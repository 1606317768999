import { Answer, AnswerInput, Field, FieldType, Maybe } from "@src/types";
import {
  answersArrayToEquationValue,
  getEquationAggregateQuestions,
  getEquationCalculatedQuestions,
  getEquationInnerFieldInitialValues,
  getEquationInputQuestions,
} from "./equations";
import {
  ConditionalValue,
  EquationValue,
  InitialValuesObject,
  InnerFields,
  IntegratedDataFieldValue,
  RepeatableValue,
} from "./formValuesTypes";
import { getFieldKey, getInnerFieldsFromConditional, getNextRowOrder } from "./getters";

type AnswerGetterArgs = {
  field: Field;
  order?: number;
  answers?: AnswerInput[];
  answersDictionary?: Record<string, AnswerInput>;
  integratedDataOnFileDictionary?: Record<string, AnswerInput>;
};

type AnswerGetter<ReturnType> = (args: AnswerGetterArgs) => ReturnType;

const getSimpleValue: AnswerGetter<AnswerInput> = ({ field, order, answersDictionary }) => {
  const answerkey = getAnswerKey({
    fieldId: field.id || "",
    questionId: field.question?.id || "",
    order: order || null,
  });

  const existingAnswer = answersDictionary && answersDictionary[answerkey];

  return existingAnswer
    ? { ...existingAnswer, value: existingAnswer.value || "" }
    : ({
        value: "",
        fileType: null,
        fieldId: field.id || "",
        questionId: field.question?.id || "",
        order,
      } as AnswerInput);
};

const getAddressValue: AnswerGetter<InnerFields> = ({
  field: addressField,
  order,
  answersDictionary,
}) => {
  const fields = addressField.fields || [];

  const innerFields = fields.reduce((acc, innerField) => {
    const isHidden = innerField.question?.hidden;

    if (!isHidden) {
      const innerFieldKey = getFieldKey(innerField);

      // Address answers have the fieldId of the container Address field and questionId
      // of the inner field (Street Address, Postal Code, etc.)
      const answerKey = getAnswerKey({
        fieldId: addressField.id,
        questionId: innerField.question?.id,
        order,
      });

      const selectedAnswer = answersDictionary && answersDictionary[answerKey];

      acc[innerFieldKey] =
        selectedAnswer ||
        ({
          value: "",
          fileType: null,
          fieldId: addressField.id || "",
          questionId: innerField.question?.id || "",
          order,
        } as AnswerInput);
    }

    return acc;
  }, {} as InnerFields);

  return innerFields;
};

const getConditionalValue: AnswerGetter<ConditionalValue> = ({
  field,
  order,
  answersDictionary,
}) => {
  const fields = getInnerFieldsFromConditional(field);

  const answer = getSimpleValue({
    field,
    order,
    answersDictionary,
  });

  const innerFields = getFormInitialValues({
    fields,
    order,
    answersDictionary,
  });

  return {
    answer,
    ...innerFields,
  };
};

const getRepeatableValue: AnswerGetter<RepeatableValue> = ({ field, answers: allAnswers }) => {
  const fields = field.fields || [];

  const fieldIds = fields.map((field) => field.id || "");

  const answers = allAnswers
    ? allAnswers.filter((answer) => fieldIds.includes(answer.fieldId))
    : [];

  const innerFields = getFormInitialValues({
    fields,
    order: getNextRowOrder(answers),
    answersDictionary: {},
  });

  return {
    answers,
    isDirty: false,
    ...innerFields,
  };
};

const getEquationValue: AnswerGetter<EquationValue> = ({ field, answers }) => {
  const aggregateQuestions = getEquationAggregateQuestions(field);

  const { rows, aggregates, allAnswers } = answersArrayToEquationValue({
    answers: answers || [],
    fieldId: field.id,
    aggregateQuestions,
  });

  return {
    rows,
    aggregates,
    allAnswers: allAnswers,
    isDirty: false,
  };
};

const getEquationInnerFields = (field: Field): InnerFields => {
  const inputQuestions = getEquationInputQuestions(field);
  const calculatedQuestions = getEquationCalculatedQuestions(field);

  return getEquationInnerFieldInitialValues({
    inputQuestions,
    calculatedQuestions,
  });
};

const getPhoneValue: AnswerGetter<AnswerInput> = ({ field, order, answersDictionary }) => {
  const answerkey = getAnswerKey({
    fieldId: field.id || "",
    questionId: field.question?.id || "",
    order: order || null,
  });

  const existingAnswer = answersDictionary && answersDictionary[answerkey];

  return existingAnswer
    ? { ...existingAnswer, value: existingAnswer.value || "" }
    : ({
        value: "",
        fileType: null,
        fieldId: field.id || "",
        questionId: field.question?.id || "",
        order,
      } as AnswerInput);
};

const getCheckboxValue: AnswerGetter<AnswerInput> = ({ field, order, answersDictionary }) => {
  const answerkey = getAnswerKey({
    fieldId: field.id || "",
    questionId: field.question?.id || "",
    order: order || null,
  });

  const existingAnswer = answersDictionary && answersDictionary[answerkey];

  return existingAnswer
    ? { ...existingAnswer, value: existingAnswer.value || "" }
    : ({
        value: "",
        fileType: null,
        fieldId: field.id || "",
        questionId: field.question?.id || "",
        order,
      } as AnswerInput);
};

const getIntegratedValue: AnswerGetter<IntegratedDataFieldValue> = ({
  field,
  order,
  answersDictionary,
  integratedDataOnFileDictionary,
}) => {
  const key = field.question?.dataValue?.split(":")[1] || "";
  const responseAnswer =
    answersDictionary &&
    answersDictionary[
      getAnswerKey({
        fieldId: field.fields?.[0].id,
        questionId: field.fields?.[0].question?.id,
        order: null,
      })
    ];
  const existingAnswer = integratedDataOnFileDictionary && integratedDataOnFileDictionary[key];

  const answer = existingAnswer
    ? {
        value: existingAnswer.value || "",
        fileType: null,
        fieldId: field.fields?.[0].id || "",
        questionId: field.fields?.[0].question?.id || "",
        order,
      }
    : ({
        value: "",
        fileType: null,
        fieldId: field.fields?.[0].id || "",
        questionId: field.fields?.[0].question?.id || "",
        order,
      } as AnswerInput);

  const innerFields = field.fields
    ? getFormInitialValues({
        fields: field.fields,
        order,
        answersDictionary,
        integratedDataOnFileDictionary,
      })
    : [];

  const fieldKey = field.fields ? getFieldKey(field.fields[0]) : "";

  if (isInitialValuesObject(innerFields)) {
    innerFields[fieldKey] = responseAnswer || answer;
  }

  return {
    answer,
    ...innerFields,
  };
};

function isInitialValuesObject(obj: unknown): obj is InitialValuesObject {
  return typeof obj === "object" && obj !== null && !Array.isArray(obj);
}

const fieldAnswersRecord = {
  [FieldType.Address]: getAddressValue,
  [FieldType.MultiFieldConditional]: getConditionalValue,
  [FieldType.Conditional]: getConditionalValue,
  [FieldType.SelectConditional]: getConditionalValue,
  [FieldType.RepeatableQuestion]: getRepeatableValue,
  [FieldType.Multiselect]: getCheckboxValue,
  [FieldType.Selectmultiple]: getCheckboxValue,
  [FieldType.Date]: getSimpleValue,
  [FieldType.Time]: getSimpleValue,
  [FieldType.Yesno]: getSimpleValue,
  [FieldType.Upload]: getSimpleValue,
  [FieldType.Multiline]: getSimpleValue,
  [FieldType.Rating]: getSimpleValue,
  [FieldType.Select]: getSimpleValue,
  [FieldType.Dropdown]: getSimpleValue,
  [FieldType.Singleline]: getSimpleValue,
  [FieldType.PostalZip]: getSimpleValue,
  [FieldType.Equations]: getEquationValue,
  [FieldType.Phonenumber]: getPhoneValue,
  [FieldType.IntegratedData]: getIntegratedValue,
};

type AnswerGetterKey = keyof typeof fieldAnswersRecord;

type GetAnswerKeyArgs = {
  fieldId?: string | null;
  questionId?: string | null;
  order?: number | null;
};

const getAnswerKey = ({ fieldId, questionId, order }: GetAnswerKeyArgs) =>
  `${fieldId || ""}_${questionId || ""}_${order || ""}`;

const getAnswersDictionary = <T extends AnswerInput | Answer>(answers: T[]) =>
  answers.reduce((acc, answer) => {
    const answerKey = getAnswerKey(answer);

    acc[answerKey] = answer;
    return acc;
  }, {} as Record<string, T>);

const getIntegratedDataDictionary = (answers: AnswerInput[]) =>
  answers.reduce((acc, answer) => {
    const answerKey = answer.questionId;

    acc[answerKey] = answer;
    return acc;
  }, {} as Record<string, AnswerInput>);

type GetFormInitialValues = {
  fields: Field[];
  answers?: AnswerInput[];
  integratedDataOnFile?: Maybe<AnswerInput[]>;
  answersDictionary?: Record<string, AnswerInput>;
  integratedDataOnFileDictionary?: Record<string, AnswerInput>;
  order?: number;
};

const getFormInitialValues = ({
  fields,
  answers,
  integratedDataOnFile,
  answersDictionary,
  integratedDataOnFileDictionary,
  order,
}: GetFormInitialValues): InitialValuesObject => {
  const newDictionary = answersDictionary || getAnswersDictionary(answers || []);
  const integratedDataOnFileDict =
    integratedDataOnFileDictionary || getIntegratedDataDictionary(integratedDataOnFile || []);

  const reducer = (acc: InitialValuesObject, field: Field) => {
    const questionId = getFieldKey(field);

    const answerGetterFunction = fieldAnswersRecord[field.type as AnswerGetterKey];

    if (answerGetterFunction) {
      acc[questionId] = answerGetterFunction({
        field,
        order,
        answers,
        integratedDataOnFileDictionary: integratedDataOnFileDict,
        answersDictionary: newDictionary,
      });
    }

    return acc;
  };

  const initialValues = fields.reduce(reducer, {} as InitialValuesObject);

  return initialValues;
};

export {
  getFormInitialValues,
  getEquationValue,
  getEquationInnerFields,
  getAnswersDictionary,
  getAnswerKey,
};
