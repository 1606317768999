import { AttachmentComponent } from "@src/components/atoms/Attachment";
import { ContentWrapper, PageWrapper } from "@src/components/layout";
import {
  Attachment,
  LinkAttachment,
  ResponseSource,
  useCreateResponseMutation,
  useResponseVersionsLazyQuery,
  useUpdateTagMutation,
} from "@src/types";
import { FormikHelpers } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { Froala } from "@src/components/atoms/Froala";
import { LinkAttachmentComponent } from "@src/components/atoms/LinkAttachment/LinkAttachment";
import { PrimaryAttachment } from "@src/components/atoms/PrimaryAttachment/PrimaryAttachment";
import { Flex, Push } from "@src/components/layout/Page";
import { DisplayAnswers } from "@src/components/molecules/displayAnswers/DisplayAnswers";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { PageFooter } from "@src/components/molecules/pageFooter";
import { PageHeader } from "@src/components/molecules/pageHeader";
import { StyledLabel, StyledSectionHeader } from "@src/components/styles";
import { useBreakPoints, useUrlParams } from "@src/customHooks";
import { useFormInfo } from "@src/customHooks/useFormInfo";
import {
  AccessDataAnswers,
  HasAccess,
  RequestError,
  ResponseVersionHash,
  SigneeInfoValues,
} from "@src/localVariables";
import {
  createResponseMutationUpdater,
  updateTagMutationUpdater,
} from "@src/mutations/updaterFunctions";

import {
  AttendeeFormRoute,
  AttendeeInformationRoute,
  PreviewProps,
  ReviewRoute,
  SelectResponseRoute,
  SigneeFormRoute,
  SigneeInfoRoute,
} from "@src/Routes";
import {
  getAttachments,
  getLinkAttachments,
  getPublicOrganizerAnswers,
  getPublicOrganizerFields,
} from "@src/utils/getters";
import { getPreviewSignee, previewOnCreateResponse } from "@src/utils/preview";
import { ErrorComponent } from "../Error";
import { FormArchived, FormClosed, FormCutoff, FormInDraft } from "../FormStatus";
import { EventDates } from "./EventDates";
import { SigneeInfoForm } from "./form";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { hasSigneeQuestions, hasTickets } from "@src/utils/formGetters";
import {
  isAuthenticatedResponse,
  isProcess,
  isRostered,
  isStaff,
  signeeValuesAreUpdated,
  tagValueIsUpdated,
} from "@src/utils/responseVersionGetters";
import { SelectResponseState } from "../SelectResponse";
import { useCachedEligibleTags } from "@src/customHooks/useEligibleTags";
import { useGoogleTranslate } from "@src/utils/translation";

const SigneeInfo: FC<PreviewProps> = ({ isPreview }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { baseUrl, countryCode, hashedOrgId, markAsVisited } = useUrlParams();
  const hasAccess = useReactiveVar(HasAccess);
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const screenSize = useBreakPoints();
  const client = useApolloClient();
  const accessData = useReactiveVar(AccessDataAnswers) || [];
  const [updateTag] = useUpdateTagMutation();
  const googleTranslate = useGoogleTranslate();

  const formInfo = useFormInfo();
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);
  const eligibleTagsQuery = useCachedEligibleTags(
    formInfo.form?.documentId || "",
    responseVersionQuery.responseVersion?.responseOrganizationId || "",
    responseVersionQuery.responseVersion?.signeeEmail || ""
  );

  const guardianRoleLabel = googleTranslate(
    formInfo?.form?.document?.organization?.roleLabels?.guardian || ""
  );

  const header = formInfo?.form?.isGeneralSignup
    ? t("pages.signeeInfo.anyoneInfo")
    : t("pages.signeeInfo.guardianInfo", {
        parent: guardianRoleLabel,
      });

  const [listResponseVersions, listResponseQuery] = useResponseVersionsLazyQuery();
  const [createResponse, createResponseQuery] = useCreateResponseMutation();

  if (createResponseQuery.error) {
    RequestError(createResponseQuery.error);
  }

  if (listResponseQuery.error) {
    RequestError(listResponseQuery.error);
  }

  if (!baseUrl) return <Navigate to={`${window.location.pathname.replace(SigneeInfoRoute, "")}`} />;

  if (formInfo.loading) return <LoadingModal message={t("messages.loadingYourExperience")} />;
  if (formInfo.error) return <ErrorComponent error={formInfo.error} />;
  if (responseVersionQuery.loading) return <LoadingModal />;
  if (responseVersionQuery.error)
    return <ErrorComponent error={responseVersionQuery.error ?? ""} />;
  if (eligibleTagsQuery.loading) return <LoadingModal />;
  if (eligibleTagsQuery.error) return <ErrorComponent error={eligibleTagsQuery.error ?? ""} />;

  const form = formInfo.form;
  const responseVersion = responseVersionQuery.responseVersion;
  const responseVersionId = responseVersion?.responseVersionId ?? "";
  const eligibleTags = eligibleTagsQuery.eligibleTags ?? [];

  if (!form) return <ErrorComponent error="Form not found." />;
  if (form.status === 0 && !isPreview) return <FormInDraft form={form} />;
  if (form.status === 4) return <FormClosed form={form} />;
  if (form.status === 5) return <FormArchived form={form} />;
  if (form.hasReachedResponseCutOffDate) return <FormCutoff form={form} />;

  // Marks the homepage ("") as visited.
  markAsVisited("");

  const attachments = getAttachments(form);
  const linkAttachments = getLinkAttachments(form);
  const primaryAttachment = attachments.find((x) => x.isPrimary);
  const publicOrganizerFields = getPublicOrganizerFields(form);
  const publicOrganizerAnswers = getPublicOrganizerAnswers(form);

  const initialValues: SigneeInfoValues =
    !isPreview || responseVersion
      ? {
          firstName: responseVersion?.signeeFirstName ?? "",
          lastName: responseVersion?.signeeLastName ?? "",
          email: responseVersion?.signeeEmail ?? "",
          notes: responseVersion?.signeeNotes ?? "",
          attachmentId: responseVersion?.signeeAttachmentId ?? "",
          responseSource:
            isStaff(responseVersion) || isProcess(responseVersion)
              ? responseVersion.source
              : ResponseSource.Organic,
          tagId: responseVersion?.tagId
            ? responseVersion.tagId
            : eligibleTags && eligibleTags.length === 1
            ? eligibleTags[0]?.id
            : "",
        }
      : getPreviewSignee(null);

  const navigateToNextPage = () => {
    if (form?.isGeneralSignup) {
      if (hasSigneeQuestions(form)) navigate(`${baseUrl}/${SigneeFormRoute}`);
      else if (hasTickets(form)) {
        navigate(`${baseUrl}/${AttendeeFormRoute}/0`);
      } else navigate(`${baseUrl}/${ReviewRoute}`);
    } else navigate(`${baseUrl}/${AttendeeInformationRoute}`);
  };

  const onSubmit = !isPreview
    ? async (values: SigneeInfoValues, { setSubmitting }: FormikHelpers<SigneeInfoValues>) => {
        const signeeValuesUpdated = signeeValuesAreUpdated(responseVersion, values);
        const tagValueUpdated = tagValueIsUpdated(responseVersion, values);

        if (signeeValuesUpdated) {
          const isNewSignee = !responseVersion;
          if (isNewSignee) {
            // Check for existing response versions for this signee
            const responseVersionsQuery = await listResponseVersions({
              variables: {
                email: values.email,
                countryCode,
                documentId: form.documentId,
              },
            });

            if (!responseVersionsQuery?.error) {
              const responses = responseVersionsQuery?.data?.ResponseVersions || [];
              if (responses.length) {
                // this info is needed on the next page, pass it along
                const params: SelectResponseState = {
                  documentId: form.documentId,
                  email: values.email,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  responseSource: ResponseSource.Organic,
                  tagId: null,
                };

                navigate(`${baseUrl}/${SelectResponseRoute}`, { state: params });
                return;
              }
            }
          }

          // Create new response/update signee values
          const newResponse = await createResponse({
            variables: {
              documentId: form.documentId,
              countryCode,
              signee: values,
              organizationHash: hashedOrgId || null,
              responseVersionId,
              accessData,
            },
            update: createResponseMutationUpdater(), // add the result to Apollo cache
          });

          setSubmitting(false);

          if (!newResponse.errors && newResponse.data) {
            ResponseVersionHash(newResponse.data.CreateResponse.hash);

            if (tagValueUpdated) {
              await updateTag({
                variables: {
                  documentId: form.documentId,
                  countryCode,
                  tagId: values.tagId !== "" ? values.tagId : null,
                  responseVersionId: responseVersion.responseVersionId,
                },
              });
            }

            navigateToNextPage();
          }
        } else if (tagValueUpdated) {
          await updateTag({
            update: updateTagMutationUpdater({
              tagId: values.tagId !== "" ? values.tagId : null,
              responseHashedId: responseVersion.hash,
            }),
            variables: {
              documentId: form.documentId,
              countryCode,
              tagId: values.tagId !== "" ? values.tagId : null,
              responseVersionId: responseVersion.responseVersionId,
            },
          });

          navigateToNextPage();
        } else navigateToNextPage();
      }
    : (values: SigneeInfoValues, { setSubmitting }: FormikHelpers<SigneeInfoValues>) => {
        const previewResponse = previewOnCreateResponse(
          form.documentId,
          form.id,
          form.isGeneralSignup,
          values,
          countryCode,
          client
        );
        ResponseVersionHash(previewResponse.hash);
        setSubmitting(false);
        navigateToNextPage();
      };

  if (responseVersion && isRostered(responseVersion) && !hasAccess)
    return <Navigate to={baseUrl} />;

  if (isAuthenticatedResponse(responseVersion) && (!eligibleTags || eligibleTags.length === 0))
    navigateToNextPage();

  return form ? (
    <>
      <PageWrapper>
        <PageHeader isPreview={isPreview} />
        <ContentWrapper screenSize={screenSize}>
          {listResponseQuery?.loading ? (
            <LoadingModal message={t("messages.retrievingResponses")} />
          ) : null}
          {createResponseQuery?.loading ? (
            <LoadingModal
              message={
                responseVersionId ? t("messages.loadingResponse") : t("messages.creatingResponse")
              }
            />
          ) : null}

          <EventDates
            startDate={form.startDate ?? null}
            endDate={form.endDate ?? null}
            cutOffDate={form.responseCutOffLocalDate ?? null}
          />

          {publicOrganizerFields.length > 0 && (
            <DisplayAnswers
              shouldTranslateAnswers
              fields={publicOrganizerFields}
              answers={publicOrganizerAnswers}
              countryCode={countryCode}
              documentId={form.documentId}
              responseVersionId={responseVersionId}
              uploadedFileViewBaseUrl={`${
                process.env.DOCUMENTS_URL
              }media/content/${countryCode.toLowerCase()}/get`}
            />
          )}

          <Froala text={googleTranslate(form.information || "")} />

          {primaryAttachment ? <PrimaryAttachment attachment={primaryAttachment} /> : null}

          {attachments && attachments.filter((x) => !x.isPrimary).length > 0 ? (
            <Flex
              flexDirection="column"
              style={{
                marginBottom: !linkAttachments || linkAttachments.length === 0 ? "45px" : "15px",
              }}
            >
              <StyledLabel>{t("pages.signeeInfo.imagesAndDocuments")}</StyledLabel>
              {attachments
                .filter((x) => !x.isPrimary)
                .map((attachment: Attachment) => (
                  <AttachmentComponent key={attachment.id} attachment={attachment} />
                ))}
            </Flex>
          ) : null}

          {linkAttachments && linkAttachments.length > 0 ? (
            <Flex flexDirection="column" style={{ marginBottom: "45px" }}>
              <StyledLabel>{t("pages.signeeInfo.externalLinks")}</StyledLabel>
              {linkAttachments.map((attachment: LinkAttachment) => (
                <LinkAttachmentComponent key={attachment.id} attachment={attachment} />
              ))}
            </Flex>
          ) : null}

          <StyledSectionHeader>{header}</StyledSectionHeader>
          <SigneeInfoForm
            initialValues={initialValues}
            orgRoleLabels={form?.document?.organization?.roleLabels || null}
            documentId={form?.documentId}
            responseVersion={responseVersion}
            eligibleTags={eligibleTags || []}
            isGeneralSignup={form.isGeneralSignup}
            onSubmit={onSubmit}
          />
        </ContentWrapper>
        <Push />
      </PageWrapper>
      <PageFooter />
    </>
  ) : null;
};

export { SigneeInfo };
