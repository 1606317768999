import gql from "graphql-tag";

const FormAttachmentFragment = gql`
  fragment FormAttachmentFragment on Attachment {
    id
    friendlyName
    name
    isPdf
    contentUri
    thumbnailUri
    printUri
    downloadUri
    fileType
    readableFileSize
    mimeType
    isPdf
    isImage
    sizeInBytes
    isPrimary
  }
`;

const LinkAttachmentFragment = gql`
  fragment LinkAttachmentFragment on LinkAttachment {
    id
    title
    description
    uri
    imageUri
    originalUri
  }
`;

const FieldFragment = gql`
  fragment FieldFragment on Field {
    id
    type
    collapsable
    readonly
  }
`;

const QuestionFragment = gql`
  fragment QuestionFragment on Question {
    id
    name
    isRequired
    labelText
    placeHolderText
    dataValue
    characterLimit
    format
    valueType
    valueOptions
    hidden
    sortValueOptions
    pinnedValueOptions
    minEntries
    maxEntries
    supportsApprovers
    plainLabel
  }
`;

const AnswerFragment = gql`
  fragment AnswerFragment on Answer {
    answerId
    value
    fieldId
    fileType
    order
    questionId
  }
`;

const TicketFragment = gql`
  fragment TicketFragment on Ticket {
    id
    name
    description
    startSellingDate
    endSellingDate
    imageId
    typeCode
    minimumQuantity
    maximumQuantity
    price
    passAlongFees
    ticketId
    documentVersionId
    formQuestionsId
    inventory {
      id
      isLimited
      remaining
      draftInitial
      draftRemaining
    }
    formQuestions {
      ...FieldFragment
      equationSections {
        ...QuestionFragment
      }
      question {
        ...QuestionFragment
      }
      fields {
        ...FieldFragment
        equationSections {
          ...QuestionFragment
        }
        question {
          ...QuestionFragment
        }
        fields {
          ...FieldFragment
          equationSections {
            ...QuestionFragment
          }
          question {
            ...QuestionFragment
          }
          fields {
            ...FieldFragment
            equationSections {
              ...QuestionFragment
            }
            question {
              ...QuestionFragment
            }
          }
        }
      }
    }
  }
  ${FieldFragment}
  ${QuestionFragment}
`;

const TicketTypeFragment = gql`
  fragment TicketTypeFragment on TicketTypeInfo {
    name
    id
    defaultPrice
    code
    sortOrder
  }
`;

const PurchasedTicketFragment = gql`
  fragment PurchasedTicketFragment on PurchasedTicket {
    type {
      ...TicketTypeFragment
    }
    name
    orderNumber
    description
    price
    totalPrice
    quantity
    createdDate
    sortOrder
    ticketId
    ticketVersionId
    purchasedTicketIds
    attendeeId
    answers {
      ...AnswerFragment
    }
  }
  ${TicketTypeFragment}
  ${AnswerFragment}
`;

const AttendeeFragment = gql`
  fragment AttendeeFragment on Attendee {
    responseAttendeeId
    attendeeId
    firstName
    lastName
    permitted
    manualPermissionDate
    attachmentId
    attachmentFileType
    notes
    tickets {
      ...PurchasedTicketFragment
    }
    attendeeAnswers {
      ...AnswerFragment
    }
    integratedDataOnFile {
      ...AnswerFragment
    }
  }
  ${PurchasedTicketFragment}
  ${AnswerFragment}
`;

const OrderFragment = gql`
  fragment OrderFragment on Order {
    orderId
    canRefund
    wasManuallyPaid
    orderNumber
    date
    subTotal
    grandTotal
    grandTotalOriginal
    hasBeenPaid
    isRefunded
    isCheckedOut
    baseFee
    baseFeeString
    totalFees
    totalFeesString
    paymentTypes
    refundedAmount
    amountOutstanding
    purchaserFirstName
    purchaserLastName
    purchasedTickets {
      ...PurchasedTicketFragment
    }
  }
  ${PurchasedTicketFragment}
`;

const ResponseVersionFragment = gql`
  fragment ResponseVersionFragment on ResponseVersion {
    responseVersionId
    documentVersionId
    documentHashId
    hash
    completedByOrganizationName
    responseOrganizationId
    isRostered
    orderStatus
    isPaymentRequired
    status
    total
    subTotal
    signeeEmail
    signeeFirstName
    signeeLastName
    isSigned
    isCheckedOut
    createdDate
    attendees {
      ...AttendeeFragment
    }
    source
    sourceId
    isInternal
    isManual
    signedDate
    signeeNotes
    signeeAttachmentId
    signeeAttachmentFileType
    originalValueUri
    signeeAnswers {
      ...AnswerFragment
    }
    orders {
      ...OrderFragment
    }
    previousOrders {
      ...OrderFragment
    }
    hash
    tagId
  }
  ${AttendeeFragment}
  ${PurchasedTicketFragment}
  ${AnswerFragment}
  ${OrderFragment}
`;

const IntegratedDataValueFragment = gql`
  fragment IntegratedDataValueFragment on IntegratedDataValue {
    value
    integratedDataFieldId
    profileId
  }
`;

const OrganizationFragment = gql`
  fragment OrganizationFragment on Organization {
    id
    name
    logo
    hasSafePay
    countryCode
    currencyCode
    disclaimer
    roleLabels {
      organization
      administrator
      organizer
      guardian
      attendee
    }
    accessFields {
      key
      field {
        ...FieldFragment
        question {
          ...QuestionFragment
        }
      }
    }
  }
  ${FieldFragment}
`;

const DocumentFragment = gql`
  fragment DocumentFragment on Document {
    id
    disableResponseEditing
    disableAttendeeNameEditing
    showContactForm
    organization {
      ...OrganizationFragment
      parent {
        ...OrganizationFragment
      }
    }
  }
  ${OrganizationFragment}
`;

const TaxFragment = gql`
  fragment TaxFragment on Tax {
    gst
    gstLabel
    pst
    pstLabel
    total
  }
`;

const TaxAndFeeEstimateFragment = gql`
  fragment TaxAndFeeEstimateFragment on TaxAndFeeEstimate {
    amountToCharge
    applicationFee
    applicationFeeTax {
      ...TaxFragment
    }
    method
    provider
    providerFee
    applicationFeeNet
    totalFeesAndTaxes
    organizationNet
  }
  ${TaxFragment}
`;

const TaxesAndFeesFragment = gql`
  fragment TaxesAndFeesFragment on TaxesAndFees {
    amount
    currency
    estimates {
      ...TaxAndFeeEstimateFragment
    }
    organizationName
    typicalMethodEstimate {
      ...TaxAndFeeEstimateFragment
    }
  }
  ${TaxAndFeeEstimateFragment}
`;

const TicketInventoryFragment = gql`
  fragment TicketInventoryFragment on TicketInventory {
    initial
    isLimited
    purchased
    remaining
    ticketId
  }
`;

const TagFragment = gql`
  fragment TagFragment on Tag {
    id
    displayName
  }
`;

export {
  FormAttachmentFragment,
  LinkAttachmentFragment,
  FieldFragment,
  QuestionFragment,
  ResponseVersionFragment,
  AttendeeFragment,
  AnswerFragment,
  IntegratedDataValueFragment,
  OrganizationFragment,
  DocumentFragment,
  TicketFragment,
  TicketTypeFragment,
  PurchasedTicketFragment,
  OrderFragment,
  TaxesAndFeesFragment,
  TicketInventoryFragment,
  TagFragment,
};
