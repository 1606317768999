import { useReactiveVar } from "@apollo/client/react/hooks/useReactiveVar";
import { LoadingModal } from "@src/components/molecules/loadingModal/LoadingModal";
import { useCachedResponseVersion } from "@src/customHooks/useResponseVersion";
import { useUrlParams } from "@src/customHooks/useUrlParams";
import { AccessDataAnswers, HasAccess, ResponseVersionHash, UrlParams } from "@src/localVariables";
import { SelectResponseRoute, SigneeInfoRoute } from "@src/Routes";
import {
  AccessField,
  AnswerInput,
  ResponseSource,
  useFormInfoQuery,
  useValidateAccessMutation,
} from "@src/types";
import { getFormInitialValues } from "@src/utils/formValues";
import { checkResponseVersionSource } from "@src/utils/typeConverters";
import React, { useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { ErrorComponent } from "../Error/Error";
import { AccessDataForm } from "./form";
import { useCachedEligibleTags } from "@src/customHooks/useEligibleTags";
import { FormArchived, FormClosed, FormCutoff, FormInDraft } from "../FormStatus";

const AccessData: FC = () => {
  const { t } = useTranslation();
  const { hashedId } = useParams();
  const { baseUrl, documentHashedId, countryCode } = useUrlParams();
  const accessData = useReactiveVar(AccessDataAnswers) || [];
  const [validateAccess, validateAccessQuery] = useValidateAccessMutation();
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const query = useFormInfoQuery({ variables: { hashedId: documentHashedId || "", countryCode } });
  const responseVersionHash = useReactiveVar(ResponseVersionHash);
  const { visitedPages } = useReactiveVar(UrlParams);

  const form = query.data && query.data.Form ? query.data.Form : null;
  const responseVersionQuery = useCachedResponseVersion(responseVersionHash);
  const eligibleTagsQuery = useCachedEligibleTags(
    query.data?.Form?.documentId || "",
    responseVersionQuery.responseVersion?.responseOrganizationId || "",
    responseVersionQuery.responseVersion?.signeeEmail || ""
  );

  const requiresAccessAnswers = form
    ? form.restrictResponses
      ? (form.document.organization.accessFields &&
          form.document.organization.accessFields.length > 0) ||
        (form.document.organization.parent &&
          form.document.organization.parent.accessFields &&
          form.document.organization.parent.accessFields.length > 0)
      : false
    : false;

  const documentId = form?.document.id || "";

  if (validateAccessQuery?.data) HasAccess(!!validateAccessQuery.data.ValidateAccess);
  else HasAccess(!requiresAccessAnswers);

  const hasAccess = useReactiveVar(HasAccess);

  if (query.loading || responseVersionQuery.loading)
    return <LoadingModal message={t("messages.loadingYourExperience")} />;
  if (validateAccessQuery.loading) return <LoadingModal message={t("messages.validating")} />;
  if (query.error) return <Navigate to={`/r/${hashedId}`} />;

  // Check form status
  if (form?.status === 0) return <FormInDraft form={form} />;
  if (form?.status === 4) return <FormClosed form={form} />;
  if (form?.status === 5) return <FormArchived form={form} />;
  if (form?.hasReachedResponseCutOffDate) return <FormCutoff form={form} />;

  if (validateAccessQuery.error) return <ErrorComponent error={validateAccessQuery.error} />;
  if (responseVersionQuery.error)
    return <ErrorComponent error={responseVersionQuery.error ?? ""} />;
  if (!form) return <Navigate to={`/r/${hashedId}`} />;
  if (eligibleTagsQuery.loading) return <LoadingModal />;
  if (eligibleTagsQuery.error) return <ErrorComponent error={eligibleTagsQuery.error ?? ""} />;

  const responseVersion = responseVersionQuery.responseVersion;

  if (hasAccess) {
    if (
      responseVersion?.responseVersionId &&
      form?.document.id &&
      !checkResponseVersionSource(responseVersion, ResponseSource.Manual) &&
      visitedPages.length === 0
    ) {
      return <Navigate to={`${baseUrl}/${SelectResponseRoute}`} />;
    } else {
      return <Navigate to={`${baseUrl}/${SigneeInfoRoute}`} />;
    }
  }

  const accessFields =
    form.document.organization.accessFields && form.document.organization.accessFields.length > 0
      ? (form.document.organization.accessFields as AccessField[])
      : form.document.organization.parent?.accessFields
      ? (form.document.organization.parent.accessFields as AccessField[])
      : [];

  const accessAnswerInitialValues = accessFields.map((x) => {
    const accessAnswer = accessData.find((y) => y.key === x.key);

    return {
      answerId: null,
      fieldId: x?.field.id ?? "",
      questionId: x?.field.question?.id ?? "",
      value: accessAnswer?.value ?? "",
      fileType: null,
      order: null,
    };
  });

  const answers = getFormInitialValues({
    fields: accessFields.map((x) => x.field),
    answers: accessAnswerInitialValues,
  });

  const saveAnswer = async (answer: AnswerInput): Promise<string> => {
    const accessField = accessFields.find((x) => x.field.id === answer.fieldId);

    if (accessField) {
      const modifiedAnswers = accessData.filter((x) => x.key !== accessField?.key);
      modifiedAnswers.push({
        key: accessField?.key,
        value: answer.value,
      });

      AccessDataAnswers(modifiedAnswers);
    }

    // these answers are not saved to the database, they are just included in every request so returning an id is not needed.
    // TODO: update this to not need a return value
    return "";
  };

  const submit = async () => {
    setShowValidation(true);
    if (accessData.every((x) => x.value) && accessData.length === accessFields.length) {
      await validateAccess({
        variables: {
          documentId,
          responseVersionId: responseVersion?.responseVersionId ?? "",
          accessData,
          countryCode,
        },
      });
    }
  };

  return (
    <AccessDataForm
      saveAnswer={(answer) => saveAnswer(answer)}
      submit={submit}
      accessFields={accessFields.map((x) => x.field)}
      answers={answers}
      hasAccess={hasAccess}
      showValidation={showValidation}
    />
  );
};

export { AccessData };
